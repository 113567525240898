<template>
  <b-card-actions
    ref="cardA"
    title="Group List"
    :action-collapse="false"
    action-refresh
    no-body
    @refresh="getGoogleAdsMockupGroupList"
  >
    <b-card class="mb-2" no-body>
      <div class="table-filter">
        <HeroInputText
          id="filter"
          v-model="tableConfig.filter"
          placeholder="Search"
          class="filter-input"
          @input="debounce(getGoogleAdsMockupGroupList, 500)()"
        />
        <HeroTablePerPage
          v-model="tableConfig.perPage"
          :options="tableConfig.perPageOptions"
          @input="debounce(getGoogleAdsMockupGroupList)()"
        />
        <b-form-checkbox
          id="is-archived-groups"
          v-model="tableConfig.isArchived"
          name="is-archived-groups"
          @input="debounce(getGoogleAdsMockupGroupList)()"
        >
          Archived Groups
        </b-form-checkbox>
        <HeroButtonAction
          type="button"
          variant="primary"
          class="btn-add"
          @click="$bvModal.show('google-ads-mockup-group-form')"
        >
          Add
        </HeroButtonAction>
      </div>

      <!-- !! Table -->
      <b-row class="my-1">
        <b-col cols="12">
          <b-table
            show-empty
            striped
            sticky-header="100%"
            :responsive="true"
            :items="items"
            :fields="tableConfig.fields"
            :per-page="0"
            :sort-by="tableConfig.sortBy"
            :sort-direction="tableConfig.sortDirection"
            :no-sort-reset="true"
            :no-local-sorting="true"
            @sort-changed="onTableSortChanged"
          >
            <template #cell(name)="{ item }">
              <router-link :to="`/google-ads-mockup-group/${item.id}`">
                {{ item.name }}
              </router-link>
            </template>
            <template #cell(action)="{ item: { id, name } }">
              <div class="d-flex align-items-center col-gap-3">
                <feather-icon
                  v-b-tooltip="'Edit'"
                  icon="Edit2Icon"
                  size="20"
                  class="cursor-pointer"
                  @click="group = { id, name }; $bvModal.show('google-ads-mockup-group-form')"
                />
                <feather-icon
                  v-b-tooltip="`${isCopied ? 'Copied' : 'Copy'} link to clipboard`"
                  icon="GlobeIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="copyLink(id)"
                />
                <feather-icon
                  v-if="!tableConfig.isArchived"
                  v-b-tooltip="'Archive'"
                  icon="ArchiveIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="handleArchive(id)"
                />
                <feather-icon
                  v-if="tableConfig.isArchived"
                  v-b-tooltip="'Unarchived'"
                  icon="InboxIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="handleUnarchived(id)"
                />
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <!-- !! Table Footer -->
      <div class="mx-1 mb-1">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <HeroTablePagination
              v-model="tableConfig.currentPage"
              :per-page="tableConfig.perPage"
              :total-rows="tableConfig.totalRows"
              @input="debounce(getGoogleAdsMockupGroupList)()"
            />
          </b-col>
          <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
            <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
          </b-col>
        </b-row>
      </div>
    </b-card>
    <GoogleAdsMockupGroupFormModal
      :group="group"
      @hidden="group = { id: '', name: '' }"
      @form-success="getGoogleAdsMockupGroupList"
    />
  </b-card-actions>
</template>

<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import DebounceMixin from '@/mixins/debounce'
import moment from 'moment/moment'
import GoogleAdsMockupGroupFormModal from '@/views/google-ads-mockup/components/GoogleAdsMockupGroupFormModal.vue'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import axiosInstance from '@/libs/axiosInstance'

export default {
  name: 'GoogleAdsMockupGroup',
  components: {
    GoogleAdsMockupGroupFormModal,
    HeroTablePagination,
    BCardActions,
    HeroTablePerPage,
    HeroButtonAction,
    HeroTableStatus,
    HeroInputText,
  },
  mixins: [DebounceMixin],
  data() {
    return {
      isCopied: false,
      group: { id: '', name: '' },
      items: [],
      tableConfig: {
        filter: '',
        isArchived: false,
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Group Name',
            key: 'name',
            sortable: true,
          },
          {
            label: 'Number Of Mockups',
            key: 'mockup_count',
            sortable: true,
          },
          {
            label: 'Action',
            key: 'action',
            sortable: false,
          },
        ],
      },
    }
  },
  mounted() {
    this.getGoogleAdsMockupGroupList()
  },
  methods: {
    async copyLink(id) {
      this.isCopied = true
      const link = `${window.location.origin}/google-ads-mockup-group/${id}`
      await navigator.clipboard.writeText(link)
      setTimeout(() => {
        this.isCopied = false
      }, 500)
    },

    async handleArchive(id) {
      try {
        const alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to archive this group?' })

        if (alertResult.value) {
          this.$refs.cardA.showLoading = true
          await axiosInstance.delete(`google-ads-mockups/groups/${id}`)
          await this.getGoogleAdsMockupGroupList()
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.$refs.cardA.showLoading = false
      }
    },

    async handleUnarchived(id) {
      try {
        const alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to unarchived this group?' })

        if (alertResult.value) {
          this.$refs.cardA.showLoading = true
          await axiosInstance.patch(`google-ads-mockups/groups/${id}`)
          await this.getGoogleAdsMockupGroupList()
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.$refs.cardA.showLoading = false
      }
    },

    onTableSortChanged(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.getGoogleAdsMockupGroupList()
    },

    async getGoogleAdsMockupGroupList() {
      try {
        this.$refs.cardA.showLoading = true

        const {
          isArchived, perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig

        const url = `google-ads-mockups/groups?archived=${isArchived}&perPage=${perPage}&page=${currentPage}&q=${filter}&orderByColumn=${sortBy}&orderByDirection=${sortDirection}`
        const response = await axiosInstance.get(url)

        this.tableConfig.currentPage = +response.data.data.current_page
        this.tableConfig.perPage = +response.data.data.per_page
        this.tableConfig.totalRows = +response.data.data.total
        this.items = response.data.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.$refs.cardA.showLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.table-filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  padding-left: 1rem;
  padding-right: 1rem;
  .filter-input {
    flex: 0 0 100%;
    margin-bottom: 0;
    @media(min-width: 576px) {
      flex: 0 0 50%;
    }
    @media(min-width: 768px) {
      flex: 0 0 30%;
    }
  }
  .btn-add {
    width: auto;
    margin-left: auto;
  }
}
</style>
