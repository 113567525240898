<template>
  <b-modal
    id="google-ads-mockup-group-form"
    ref="google-ads-mockup-group-form"
    :title="modalTitle"
    centered
    hide-footer
    no-close-on-backdrop
    size="sm"
    @shown="onShown"
    @hidden="onHidden"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <form
      class="group-form"
      @submit.prevent="handleSubmit"
    >
      <validation-provider
        #default="{ errors }"
        ref="name"
        name="Group Name"
        rules="required"
      >
        <HeroInputText
          id="group-name"
          v-model="name"
          class="group-name"
          label="Name"
          required
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
        >
          <template #append>
            <HeroButtonAction
              variant="primary"
              type="submit"
              class="btn-action"
            >
              Save
            </HeroButtonAction>
          </template>
        </HeroInputText>
      </validation-provider>
    </form>
  </b-modal>
</template>
<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import axiosInstance from '@/libs/axiosInstance'
import SweetAlert from '@/services/SweetAlert'

export default {
  components: {
    HeroButtonAction,
    HeroInputText,
  },
  props: {
    group: {
      type: Object,
      default: () => ({
        id: '',
        name: '',
      }),
    },
  },
  data() {
    return {
      showOverlay: false,
      name: '',
    }
  },
  computed: {
    modalTitle() {
      return this.group.id ? 'Update Group' : 'Create New Group'
    },
  },
  methods: {
    onShown() {
      this.name = this.group.name
    },

    onHidden() {
      this.name = ''
      this.$emit('hidden')
    },

    async handleSubmit() {
      try {
        this.showOverlay = true
        const isNameValid = (await this.$refs.name.validate()).valid
        if (isNameValid) {
          const payload = {
            name: this.name,
          }

          if (this.group.id) {
            await axiosInstance.put(`google-ads-mockups/groups/${this.group.id}`, payload)
          } else {
            await axiosInstance.post('google-ads-mockups/groups', payload)
          }

          this.$bvModal.hide('google-ads-mockup-group-form')
          this.$swal({ ...SweetAlert.success, text: this.group.id ? 'Update Successfully' : 'Create Successfully' })
          this.$emit('form-success')
        }
      } catch (error) {
        //
      } finally {
        this.showOverlay = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.btn-action {
  width: 90px;
  min-height: 38px;
  padding: 8px;
}

.group-form {
  ::v-deep .input-group {
    column-gap: 8px;
    .form-control {
      border-radius: 0.357rem;
      border: 1px solid #d8d6de;
      &.is-invalid {
        border-color: #ea5455;
      }
      &:not(.is-invalid) {
        &:focus {
          border-color: #7367f0;
        }
      }
    }
  }
  .group-name {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
}
</style>
